// src/App.js

import React, { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

function App() {
  const [query, setQuery] = useState('');
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');

  const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently, user, isLoading } = useAuth0();

  // Backend URL
  const backendUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8001';

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(`Sending request to backend at ${backendUrl}/chat`);

      // Get the access token
      const token = await getAccessTokenSilently();

      // Make the request with the access token in the Authorization header
      const res = await axios.post(
        `${backendUrl}/chat`,
        { user_query: query },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setResponse(res.data.response);
      setError('');
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to fetch response from backend. Check console for details.');
      setResponse('');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Chat with RAG Bot</h1>

      {!isAuthenticated ? (
        <button onClick={() => loginWithRedirect()}>Log In</button>
      ) : (
        <>
          <button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>
          <p>Welcome, {user.name}!</p>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Ask a question..."
            />
            <button type="submit">Submit</button>
          </form>

          <div>
            <h2>Response:</h2>
            <p>{response}</p>
          </div>

          {error && (
            <div style={{ color: 'red' }}>
              <h3>Error:</h3>
              <p>{error}</p>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default App;
